const DEFAULT_PASSWORD_RULES = [
    {
        pattern: /^.{8,}/,
        message: 'Password must be at least 8 characters long',
    },
    {
        pattern: /[A-Z]/,
        message: 'Password must contain an upper case character',
    },
    {
        pattern: /[a-z]/,
        message: 'Password must contain a lower case character',
    },
    {
        pattern: /[0-9]/,
        message: 'Password must contain a digit',
    },
]

class ValidatorClass {
    constructor(rules) {
        this.rules = rules
        this.error = null
    }

    mixin(rules) {
        for (const key in rules) {
            this.rules[key] = key in this.rules
                ? {
                    ...this.rules[key],
                    ...rules[key],
                }
                : rules[key]
        }
    }

    passwordContainsCount(password, allowedChars) {
        var count = 0
        for (var i = 0; i < password.length; i++) {
            var char = password.charAt(i);
            if (allowedChars.indexOf(char) >= 0) { count++; }
        }
        return count;
    }

    passwordErrorString(value, rules = DEFAULT_PASSWORD_RULES ) {
        let error = null

        for (let i = 0, len = rules.length; i < len; i++) {
            if (!rules[i].pattern.test(value)) {
                error = rules[i].message
                break
            }
        }

        return error
    }

    isRuleExists(key) {
        return key in this.rules
    }

    isRequired(key) {
        return key in this.rules && this.rules[key].required
    }

    isInvalid(key, value, custom_message = '') {
        this.error = null

        if (this.isRuleExists(key)) {
            const rule = this.rules[key]

            const value_exists = Array.isArray(value)
                ? value.length
                : rule.trim && typeof value == 'string'
                    ? value.trim()
                    : value

            if (rule.required && !value_exists) {
                this.error = `Please, enter ${ rule.name }`
            } else if (rule.customPasswordCheck) {
                this.error = this.passwordErrorString(value, rule.customPasswordRules)

                return this.error
            } else if (value_exists) {
                if (rule.maxlength && value.length > rule.maxlength) {
                    this.error = `${ rule.name } can't be longer than ${ rule.maxlength } characters`
                } else if (rule.minlength && value.length < rule.minlength) {
                    this.error = `${ rule.name } must be at least ${ rule.minlength } characters`
                } else {
                    if (rule.replaces) {
                        for (let n = 0, len = rule.replaces.length; n < len; n++) {
                            const rule_replace = rule.replaces[n]

                            if (!rule_replace.format || rule_replace.format.test(value)) {
                                value = value.replace(rule_replace.pattern, rule_replace.replace)
                            } else {
                                this.error = `${ rule.name } format is invalid`
                                break
                            }
                        }
                    }

                    if (!this.error) {
                        if (rule.format && !rule.format.test(value)) {
                            this.error = `${ rule.name } format is invalid`
                        }
                    }
                }
            }

            if (this.error && (custom_message || rule.custom_message)) {
                this.error = custom_message || rule.custom_message
            }
        } else {
            throw new Error(`Rule "${ key }" not found in validator`)
        }

        return this.error
    }

    getError() {
        return this.error
    }

    mobile(phone) {
        let mobile = phone.replace(/\D/g, '')

        if (/^\s*\+/g.test(phone)) {
            mobile = '+' + mobile
        }

        return mobile
    }
}

export default ValidatorClass
