<template>
    <div class="stage-location">
        <section class="location">
            <app-error v-model="errors.show" :message="errors.message"></app-error>
            <form @submit.prevent class="form-location">
                <app-select :options="serviceProviders" key-value="UUID" key-title="Name" v-model="assignSPUUID"></app-select>
            </form>
        </section>

        <button class="btn btn-primary btn-small btn-confirm" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'

import appSelect from '@/components/app-select'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        customerGroup: { type: Object, required: true },
    },

    components: {
        appError,
        appSelect
    },

    data() {
        return {
            serviceProviders: [],
            assignSPUUID: '',

            errors: {
                show: false,
                message: '',
                fields: {},
            },

            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            let pagination = {
                page: 1,
                limit: 20
            }
            // TODO searchable SELECT 2
            let params = {
                "SPID": this.current_spid,
                'MtxType': 'MvnoGroupExtension',
                'IncludeInactive': true,
                'SearchOptions.PageNumber': pagination.page,
                'SearchOptions.PageSize': pagination.limit,
                'SearchOptions.SortBy': 'Name',
                'SearchOptions.SortDesc': 0,
            }

            this.$store.dispatch('api_matrixx/GetMtxGroupsPaginated', params).then((resp) => {
                this.serviceProviders = resp.MtxGroups
            })
        },

        confirm() {
            this.goNext()
        },

        goNext() {
            let assignedSP = this.serviceProviders.find((o) => {
                return o.UUID == this.assignSPUUID
            })
            
            let assignDetails = {
                ParentMtxGroupUUID: this.assignSPUUID,
                MtxGroupUUID: this.customerGroup.details.UUID,
                SPID: this.current_spid
            }

            this.processing = true
            this.$store.dispatch('api_matrixx/AssignMtxGroupToMtxGroup', assignDetails).then((resp) => {
                this.$emit('go-next', {
                    fromStage: 'assign',
                    assignedSP: assignedSP
                })
                this.processing = false
            }).catch(error => {
                this.errors.show = true
                this.errors.message = errMessage(error)
                this.processing = false
            })
        },


        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }

            const errors = Object.keys(this.errors.fields)

            if (errors.length) {
                this.errors.show = true
                this.errors.message = this.errors.fields[ errors[0] ]
            } else {
                this.errors.show = false
            }

            return this.errors.show
        },
    },

    watch: {
        location: {
            handler() {
                this.$emit('ready', { location: this.location })
            },
            deep: true,
        },

        serviceAbilityMessage() {
            this.$emit('ready', { services: this.serviceAbilityMessage })
        },

        processing() {
            this.$emit('processing', this.processing)
        },
    },

    computed: {
        ...mapGetters({
            'current_spid': 'current_spid',
            'search_by_google': 'broadband_location_search_by_google',
            'search_by_locationsvc': 'broadband_location_search_by_locationsvc',
        }),
    },
}
</script>

<style lang="scss">
.stage-location {
    .location {
        width: 100%;
        max-width: 657px;

        form {
            display: flex;
            justify-content: center;
            align-items: center;

            .app-input-google-autocomplete-manually,
            .app-input-location-autocomplete {
                border-radius: $border-radius-secondary;

                input {
                    border-radius: $border-radius-secondary;
                }
            }
        }
    }

    .services {
        display: flex;
        width: 100%;
        max-width: 660px;
        margin-top: 16px;
        line-height: 24px;
        font-size: 16px;

        .prefix {
            padding-right: 32px;
            font-size: 18px;
            font-weight: bold;
            color: var(--color-text-primary);
        }

        .list {
            color: var(--color-text-primary);
        }
    }

    .location-variants {
        margin-top: 35px;

        h5 {
            font-size: 28px;
            line-height: 32px;
        }

        .app-radio-buttons {
            padding: 16px 0;
            background: transparent;

            input[type=radio] {
                &+label {
                    margin: 0;
                    padding: 7px 0;

                    &>.label {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .btn-confirm {
        margin-top: 24px;
    }
}

@media (max-width: $mobile-size) {
    .stage-location {
        .services {
            flex-direction: column;
            font-size: 14px;

            .prefix {
                font-size: 16px;
                padding: 0;
            }

            .list {
                margin-top: 4px;
            }
        }

        .location-variants {
            margin-top: 24px;

            h5 {
                font-size: 24px;
                line-height: 32px;
            }

            .app-radio-buttons {
                input[type=radio] {
                    &+label {
                        &>.label {
                            font-size: 16px;
                        }
                    }
                }
            }

            &+.actions {
                margin-top: 24px;
            }
        }
    }
}
</style>