import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    city: {
        name: 'City',
        required: true,
    },
    postal_code: {
        name: 'Postal code',
        required: true,
    },
    address: {
        name: 'Address',
        required: true,
    },
    location: {
        name: 'Location',
        required: true,
    },

    name: {
        name: 'Name',
        required: true,
        format: /^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
        custom_message: 'Sorry, that\'s not a valid name',
    },
    fullname: {
        name: 'Full name',
        required: true,
        format: /^(?=.{1,32}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
        custom_message: 'Sorry, that\'s not a valid full name. Check the details and try again.',
    },
    email: {
        name: 'Email address',
        required: true,
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        custom_message: 'Sorry, that\'s not a valid email address',
    },
    phone: {
        name: 'Mobile number',
        required: false,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/, // 1 - сначала проверяем соответсвие значения до замены
                pattern: /[^\d]/g,                           // 2 - затем заменяем исходное значение соответсвующее pattern
                replace: '',                                 //     на value
            },
        ],
        format: /^\+?\d{9,12}$/,                             // после всех предпроверок и замен - value должно соответсвовать format
        custom_message: 'Sorry, that\'s not a valid mobile number',
    },

    number: {
        name: 'Account number',
        required: true,
        custom_message: 'Enter the Account number',
    },
    bss: {
        name: 'Bss stack',
        required: true,
        format: /^[0123]$/,
        custom_message: 'Specify the Bss stack',
    },
})