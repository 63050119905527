const padZeros = (input, maxLength) => `0000${input}`.slice(-maxLength);

const dayOfWeekNames = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday'
]
const dayOfWeekNamesShort = [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'
]
const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
]
const monthNamesShort = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

// formatDate expects input to be a Javascript date object
export default function formatDate(input, format) {

    if (!input) {
        return ""
    }
    if (!format) {
        format = "YYYY-MM-DD HH:mm"
    }
    const year = input.getFullYear();
    const month = input.getMonth() + 1;
    const date = input.getDate();
    const hours24 = input.getHours();
    const hours12 = hours24 % 12 === 0 ? 12 : hours24 % 12;
    const minutes = input.getMinutes();
    const seconds = input.getSeconds();
    const weekday = input.getDay();

    /**
     * @todo
     * Функция работает некорректно при формате 'DD MMM YYYY'
     */

    return format
        .replace('YYYY', padZeros(year, 4))
        .replace('YY', padZeros(year % 100, 2))
        .replace('MMMM', monthNames[month - 1])
        .replace('MMM', monthNamesShort[month - 1])
        .replace('MM', padZeros(month, 2))
        .replace('M', `${month}`)
        .replace('DD', padZeros(date, 2))
        .replace('D', `${date}`)
        .replace('HH', padZeros(hours24, 2))
        .replace('H', `${hours24}`)
        .replace('hh', padZeros(hours12, 2))
        .replace('h', `${hours12}`)
        .replace('mm', padZeros(minutes, 2))
        .replace('m', `${minutes}`)
        .replace('ss', padZeros(seconds, 2))
        .replace('s', `${seconds}`)
        .replace('A', hours24 < 12 ? 'AM' : 'PM')
        .replace('a', hours24 < 12 ? 'am' : 'pm')
        .replace('dddd', dayOfWeekNames[weekday])
        .replace('dd', dayOfWeekNamesShort[weekday])
        .replace('d', `${weekday}`);
}
