<template>
    <div class="app-select" :class="{ selected, error, opened: isOpened }">
        <div class="trigger" @click="toggle">
            <label @click="handleLabelClick">{{ label }}<i v-if="isRequired">*</i></label>
            <span class="title">{{ title }}</span>
        </div>

        <div class="options" ref="scroll">
            <div class="scroll">
                <div class="option" v-for="option in options" :key="option[keyValue]">
                    <div class="title" @click="onSelect(option)">
                        {{ option[keyTitle] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

export default {
    props: {
        value:      { required: true },
        options:    { type: [Array, Object],   required: true },

        keyValue:   { type: String, default: 'value' },
        keyTitle:   { type: String, default: 'title' },

        label:      { type: String,  default: '' },

        isDisabled: { type: Boolean, default: false },
        isRequired: { type: Boolean, default: false },
        hasError:   { type: Boolean, default: false }
    },

    data() {
        return {
            isOpened: false,
            simple_bar: null,
        }
    },

    computed: {
        error() {
            return this.hasError
        },

        title() {
            const option = this.options.find(item => item[this.keyValue] === this.value)

            return option ? option[this.keyTitle] : ''
        },

        selected() {
            return this.title.length > 0
        },
    },

    methods: {
        onSelect(option) {
            this.$emit('input', option[this.keyValue])
            this.$emit('select', option[this.keyValue])

            if (option[this.keyValue] !== this.value) {
                this.$emit('change', option[this.keyValue])
            }

            this.close()
        },

        close() {
            this.isOpened = false
            document.removeEventListener('click', this.handleClickOutside)
            this.$emit('close')
        },

        toggle() {
            this.isOpened = !this.isOpened

            if (this.isOpened) {
                document.addEventListener('click', this.handleClickOutside)
                this.$emit('open')
            } else {
                document.removeEventListener('click', this.handleClickOutside)
                this.$emit('close')
            }
        },

        handleLabelClick(event) {
            if (this.selected) {
                event.stopPropagation()
            }
        },

        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },
    },

    mounted() {
        this.simple_bar = new SimpleBar(this.$refs.scroll, {
            autoHide: false
        })
    }
}
</script>

<style lang="scss">
.app-select {
    $option-height: 32px + 8px * 2;

    position: relative;
    width: 100%;

    .trigger {
        position: relative;
        display: flex;
        align-items: center;
        height: $option-height;
        padding: 0 40px 0 14px;

        font-size: 16px;
        line-height: 24px;

        border: solid 1px var(--color-input-border);
        border-radius: $border-radius-secondary;
        background: var(--color-input-bg);
        cursor: pointer;

        label {
            position: absolute;
            top: 50%;
            left: 14px;
            color: var(--color-input-label);
            background-color: var(--color-input-bg);
            transform: translateY(-50%);
            transition: $transition-duration-primary;

            i {
                color: var(--color-required);
                font-style: normal;
            }
        }

        .title {
            color: var(--color-input-label);

            @include text-overflow();
        }

        &::after {
            position: absolute;
            top: 11px;
            right: 8px;

            display: block;

            width: 24px;
            height: 24px;

            transition: $transition-duration-primary;

            color: var(--color-icon-calm);

            @include icon($icon-chevron-bottom);
        }

        &:hover {
            border-color: var(--color-input-border-hover);
        }
    }

    .options {
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        right: 0;
        z-index: $z-index-dropdown;
        display: flex;
        flex-direction: column;
        max-height: 3 * $option-height;
        background-color: var(--color-autocomplete-bg);
        box-shadow: var(--box-shadow-tertiary);
        border-radius: $border-radius-primary;
        display: none;

        .simplebar-track {
            &.simplebar-vertical {
                top: 15px;
                right: 10px;
                bottom: 15px;
                width: 4px;
                background-color: var(--color-scrollbar-bg);
                border-radius: 2px;

                .simplebar-scrollbar {
                    &::before {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: var(--color-scrollbar-thumb-bg);
                        border-radius: 2px;
                        opacity: 1;
                    }
                }
            }
        }

        .option {
            position: relative;
            display: flex;
            align-items: center;
            height: $option-height;

            .title {
                display: flex;
                align-items: center;
                width: 100%;
                height: 32px;
                padding: 0 24px;
                font-size: 18px;
                line-height: 24px;
                color: var(--color-autocomplete-option);
                cursor: pointer;
                user-select: none;

                @include text-overflow();

                &:hover {
                    background-color: var(--color-autocomplete-option-hover-bg);
                }
            }
        }
    }

    &.selected,
    &.opened {
        .trigger {
            &:hover {
                border-color: var(--color-input-border-hover);
            }
            
            label {
                top: 0;
                padding: 0 4px;

                color: var(--color-input-label-typing);

                i {
                    color: inherit;
                }
            }
        }
    }

    &.opened {
        .trigger {
            &::after {
                transform: rotate(180deg);
            }
        }

        .options {
            display: block;
        }
    }

    &.error {
        /* @todo */
    }
}

@media (max-width: $mobile-size) {
  .app-select {
    $option-height-mobile: 28px + 4px * 2;

    .trigger {
      font-size: 14px;
    }

    .options {
      max-height: 3 * $option-height-mobile;

      .option {
        height: $option-height-mobile;

        .title {
          height: 28px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>